.sortingDiv { padding:30px 0 15px 0;}

.serviceBlock { padding:40px 0 0 0;}
.serviceBlock h2 { padding-bottom:10px;}

.tableBlock { min-width:100%; overflow-x:scroll;}
.tableBlock .actTabel { min-width:1583px;}
.tableBlock th { background:#4a4a4a; color:#fff; font-size:14px; line-height:18px; padding:10px 15px;}
.tableBlock tr { background:#f7f7f7;}
.tableBlock tr.light { background:#ebebeb;}
.tableBlock tr td { padding:10px 15px; font-size:14px; line-height:18px;}
.paginationBlock { padding:20px 0; margin:0 auto; text-align:center;}
.paginationBlock .back {display:inline-block; margin-right:25px; }
.paginationBlock .back a { margin:0; padding:0px 5px; width:123px; font-size:14px; line-height:33px; color:#fff; border-radius:8px; display:block; font-weight:600; background:#4a4a4a; text-align:center;}
.paginationBlock .back a:hover { background:#6f6e6e; color:#fff;}
.paginationBlock .next { display:inline-block; margin-left:30px; }
.paginationBlock .next a { margin:0; padding:0px 5px; width:123px; font-size:14px; line-height:33px; color:#fff; border-radius:8px; display:block; font-weight:600; background:#ef4123; text-align:center;}
.paginationBlock .next a:hover { background:#f53918; color:#fff;}
.paginationBlock .numberPage { display:inline-block;}
.paginationBlock .numberPage ul { margin:0; padding:0;}
.paginationBlock .numberPage ul li{ margin:0; padding:0; display:inline-block; margin-left:10px;}
.paginationBlock .numberPage ul li a { margin:0; padding:0; display:block; color:#4a4a4a; line-height:30px; width:30px; text-align:center; font-size:14px; font-weight:600; border:solid 1px #4a4a4a;}
.paginationBlock .numberPage ul li a:hover { border:solid 1px #ef4123;color:#ef4123;}
.paginationBlock .numberPage ul li.active a { border:solid 1px #ef4123;color:#ef4123;}

.thumb { max-width:153px;display:inline-block; margin-right:16px;}
.thumb img {width:100%;}
.imageName { display:inline-block; font-size:14px; color:#4a4a4a; text-decoration:underline; background: none;outline: none; border: none;}
.statusActive {color:#fff; display:block; background:#21ac7b; font-size:16px; font-weight:600; width:115px; line-height:33px; text-align:center; }
.statusActive:hover {color:#fff; background:#2cc38e; }
.statusExpired {color:#fff; display:block; background:#9b9b9b; font-size:16px; font-weight:600; width:115px; line-height:33px; text-align:center;}
.statusExpired:hover {color:#fff; background:#a2a1a1; }
a.createNew { background-color:#2394b5; border-radius:8px; line-height:50px; width:220px; font-size:20px; font-weight:600; color:#fff; text-align:center; display:inline-block; margin-left:20px;}
a.createNew span { background:url(../../assets/images/plus.png) 0px center no-repeat; background-size:17px; text-align:center; padding-left:28px; }
a.createNew:hover { background:#2aa7cc;}
a.qrButton { background-color:#ef4123; border-radius:8px; line-height:50px; width:220px; font-size:20px; font-weight:600; color:#fff; text-align:center; display:inline-block;}
a.qrButton span { background:url(../../assets/images/icon-4.png) 0px center no-repeat; background-size:17px; text-align:center; padding-left:28px;}
a.qrButton:hover { background:#f84f32;}
.leftDiv { float:left;}
.rightDiv { float:right; margin-top:10px;}
.sorting {background:url(../../assets/images/filterIco.png) 0px bottom no-repeat; background-size:100%; width:5px; height:12px; display:inline-block; margin:0px 0 0 3px;}
.headerSort { cursor: pointer }
