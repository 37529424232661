/* .rightBox {
    width: 100%; 
    background-color: #fff; 
    float: right; 
    padding: 80px 40px 100px 320px;
    position: relative; 
    height: 100%;
} */

.red { color:#e30513; font-size:16px; font-weight:700;}
.clear {clear:both;height:0px;}

