.rightBox .welcomeBlock { 
    border-bottom:solid 1px #979797; 
    padding:40px 0 50px 0;
}

.rightBox .adminDate { 
    font-size:20px;
}

.rightBox h1 { 
    padding-bottom:20px; 
    display:block;
}

.rightBox .adminDate span { 
    min-width:140px; 
    display:inline-block;
}

.rightBox .serviceBlock { 
    padding:20px 0 0 0;
}

.rightBox .serviceBlock h2 { 
    padding-bottom:0px;
}

.rightBox .service { 
    border:solid 3px #9b9b9b; 
    border-radius:8px; 
    width:49%; 
    display:inline-block; 
    margin-top:20px; 
}

.rightBox .service a { 
    background-color:#4a4a4a; 
    text-align:center;
    display:block; 
    color:#FFFFFF; 
    font-size:24px; 
    font-weight:600; 
    text-align:center;
}

.rightBox .service a:hover { 
    background-color:#9b9b9b;
}

.rightBox .service.one span {
    background-image:url(../../assets/images/icon-1.png);
    background-position:left center; 
    background-repeat:no-repeat; 
    background-size:65px;
    padding:45px 0 45px 100px; 
    display:inline-block;
}

.rightBox .service.two span {
    background-image:url(../../assets/images/icon-2.png);
    background-position:left center; 
    background-repeat:no-repeat; 
    background-size:35px;  
    padding:45px 0 45px 65px; 
    display:inline-block;
}

.rightBox .service.three span {
    background-image:url(../../assets/images/icon-3.png);
    background-position:left center; 
    background-repeat:no-repeat; 
    background-size:50px;  
    padding:45px 0 45px 80px; 
    display:inline-block;
}

.rightBox .service.four span {
    background-image:url(../../assets/images/icon-4.png);
    background-position:left center; 
    background-repeat:no-repeat; 
    background-size:50px; 
    padding:45px 0 45px 80px; 
    display:inline-block;
}

.rightBox .service.one, .rightBox .service.three { 
    margin-right:2%;
}
