.menu { 
    max-width:274px; 
    background:#4a4a4a; 
    position:fixed; 
    height:100vh; 
    padding:100px 0 20px 0; 
    height: 100%;
    left: 0px;
    top: 0px; 
    z-index:99;
}

.menu ul { 
    margin:0; 
    padding:0;
}

.menu ul li { 
    margin:0; 
    padding:0px 0 18px 0; 
}

.menu ul a { 
    color:#fff; 
    font-size:20px; 
    display:block; 
    padding:10px 20px 10px 50px; 
    font-weight:500;
}

.menu ul a:hover, .menu ul li.active a { 
    background-color:#ef4123; 
    color:#fff;
}

.menu ul a.one {
    background-image:url(../../../assets/images/home.png); 
    background-position:20px center; 
    background-repeat:no-repeat; 
    background-size:20px; 
}

.menu ul a.two {
    background-image:url(../../../assets/images/icon-1.png); 
    background-position:20px center; 
    background-repeat:no-repeat; 
    background-size:20px;  
}

.menu ul a.three {
    background-image:url(../../../assets/images/icon-2.png); 
    background-position:24px center; 
    background-repeat:no-repeat; 
    background-size:12px; 
}

.menu ul a.four {
    background-image:url(../../../assets/images/icon-3.png); 
    background-position:23px center; 
    background-repeat:no-repeat; 
    background-size:16px; 
}

.menu ul a.five {
    background-image:url(../../../assets/images/icon-4.png); 
    background-position:23px center; 
    background-repeat:no-repeat; 
    background-size:16px; 
}

.menu ul ul li {
    padding:0;
}

.menu ul ul a { 
    color:#fff; 
    font-size:16px; 
    font-weight:100; 
    display:block; 
    padding:5px 20px 5px 50px;
}

.menu ul ul a:hover{}

.menu .logout { 
    position:absolute; 
    bottom:20px; 
    left:5%; 
    width:90%; 
    font-size:20px; 
    font-weight:600;
}

.menu .logout a { 
    border:solid 4px #ef4123; 
    color:#ef4123; 
    display:block; 
    text-align:center;
    line-height:42px; 
    border-radius:8px;
}

.menu .logout a:hover {
    background:#ef4123; 
    color:#fff; 
}
