.sortBy { 
    float:right;
}

.sortBy label { 
    padding-right:15px;
}

.sortBy select {
    border:solid 1px #9b9b9b; 
    border-radius:8px; 
    background-color:#f4f4f4; 
    height:33px; 
    font-size:16px; 
    color:#4a4a4a; 
    padding:0 20px 0 10px; 
    width:284px;
}