header {
    background:#ef4123; 
    padding:15px 25px; 
    position:fixed; 
    z-index:9999; 
    width:100%;
}

header .logo { 
    max-width:217px; 
    float:left;
}

header .logo img {
    width:100%;
}

header .welcome {
    float:right; 
    font-size:18px; 
    color:#FFFFFF; 
    padding-top:10px;
}
