// Details Page CSS [Start]
.serviceBlock { padding:40px 0 0 0;}
.serviceBlock h2 { padding-bottom:10px;}
.leftDiv { float:left; }
.rightDiv { 
    float:right; 
    margin-top:10px; 
}
.adminDate { 
    font-size:16px;
    span { 
        min-width:140px; 
        display:inline-block;
    }
}
.edit a{ background-color:#2394b5; border-radius:8px; line-height:50px; width:220px; font-size:20px; font-weight:600; color:#fff; text-align:center; display:block;}
.edit a span{ background:url(../../assets/images/edit.png) 0px center no-repeat; background-size:17px; text-align:center; padding-left:28px;}
.edit a:hover{ background:#2aa7cc;}

// .delete input{ background-color:#990000; border-radius:8px; line-height:50px; width:220px; font-size:20px; font-weight:600; text-align:center; display:block;
//   background:url(../../assets/images/edit.png) 0px center no-repeat; background-size:17px; text-align:center; padding-left:28px;
// }
// .delete input:hover{ background:#cc0000;}

.delete input, 
.delete input:hover, 
.delete input:focus { 
  background:url(../../assets/images/deleteIcon.png) 20px center no-repeat;  background-color:#a42525; background-size:17px; border-radius:8px; line-height:50px; width:220px; font-size:20px; font-weight:600; color:#fff; display:block; border:none; outline:none;padding-left:30px; cursor:pointer;
}

.clear{clear:both;height:0px;}

.horizTableBlock{ margin:45px 0 40px 0;}
.horizTableBlock .th{ background:#4a4a4a; color:#fff; font-weight:600; border-bottom:solid 2px #979797; width:200px;}
.horizTableBlock tr{ background:#f7f7f7;}
.horizTableBlock .first{ background:#ebebeb;}
.horizTableBlock tr td{ padding:10px 15px; font-size:16px; line-height:18px; height:75px;}
.horizTableBlock .heightBig{ height:151px;}
.horizTableBlock b.space{ margin:0 28px;}

.thumbBig{max-width: 353px; display: inline-block; margin-right: 16px;}
.thumbBig img{width:100%;}

.imageName{ display:inline-block; font-size:14px; color:#4a4a4a; text-decoration:underline; background: none;outline: none; border: none;}
.statusActive{color:#fff; display:block; background:#21ac7b; font-size:16px; font-weight:600; width:115px; line-height:33px; text-align:center; }
.statusActive:hover{color:#fff; background:#2cc38e; }
.statusExpired{color:#fff; display:block; background:#9b9b9b; font-size:16px; font-weight:600; width:115px; line-height:33px; text-align:center;}
.statusExpired:hover{color:#fff; background:#a2a1a1; }

.leftCont{float:left;  width:48%;}
.rightCont{float:right;  width:48%;}
.leftCont h3, .rightCont h3{ font-weight:600; color:#000000; padding-bottom:8px;}


// Create Page CSS [Start]
.fileUpload{ position:relative;}
.imageUploadWrap{position:relative; background:url(../images/no-image.png) center center no-repeat; background-size:100%; width:335px; display:inline-block;}
.fileUploadBtn { position:absolute; left:365px; top:50%; 
  width:155px; height:33px; line-height:33px; background:#2394b5; 
  margin:-16px 0 0 0;
  color: #fff; 
  border: none;
  padding:0px;
  border-radius: 8px;
  transition: all .2s ease;
  outline: none;
  font-weight: 700;text-align:center;
}
.fileUploadBtn span{background: url(../images/upload.png) 0px center no-repeat; padding-left:20px; background-size:15px; text-align:center; font-size:14px;}
.fileUploadBtn:hover {background: #259fc2;color: #ffffff;transition: all .2s ease;cursor: pointer;}
.fileUploadBtn:active { border: 0;transition: all .2s ease;}
.fileUploadImage {margin: auto;padding:0px;}
// .fileUploadContent {display: none;text-align: center; width:335px;}
// .fileUploadContent img{width:100%;}
.fileUploadContent {text-align: center; width:335px;}
.fileUploadInput {position: absolute;margin: 0;padding: 0;width: 100%;height: 100%;outline: none;opacity: 0;cursor: pointer; top:0;}
.imageTitleWrap {position:absolute; top:20px; left:360px;}
.dragText {text-align: center;}
.dragText h3 {font-weight: 100; text-transform: uppercase;color: #15824B;padding: 60px 0;}
.removeImage {margin: 0;color: #fff;border: none; outline: none;}
.removeImage:hover {background: #c13b2a; color: #ffffff; transition: all .2s ease;cursor: pointer;}
.removeImage:active {border: 0; transition: all .2s ease;}
.radioCont {
  display: block;
  position: relative;
  padding-left: 35px; margin-right:50px;
  cursor: pointer;
  font-size:16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; display: inline-block;
    line-height: 24px
}

/* Hide the browser's default radio button */
.radioCont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 50%; border:solid 1px #d3d3d3;
}

/* On mouse-over, add a grey background color */
.radioCont:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.radioCont input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioCont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioCont .checkmark:after {
 	top:5px;
	left:5px;
	width:12px;
	height:12px;
	border-radius: 50%;
	background: #21ac7b;
}

.input{border-radius:8px; background-color:rgba(255, 255, 255, 0.8); height:50px; padding:0 15px; line-height:20px; border:none; width:100%;}
.horizTableBlock .input{ border:solid 1px #d3d3d3;}
.horizTableBlock .textarea{ border:solid 1px #d3d3d3; height:127px; width:100%; padding:15px 15px; border-radius:8px;}
.horizTableBlock .calender{ border:solid 1px #d3d3d3; border-radius: 8px;
    background:#fff url(../images/calendar.png) 15px center no-repeat;background-size:17px;
    height: 50px;
    padding: 0 15px 0 50px;
    line-height: 20px;
    width:200px;
  }
.horizTableBlock .calenderError{ border:solid 1px #d3d3d3; border-radius: 8px;
    background:#fff0f0 url(../images/calendar.png) 15px center no-repeat;background-size:17px;
    height: 50px;
    padding: 0 15px 0 50px;
    line-height: 20px;
    width:200px;
  }
.horizTableBlock ::placeholder {color:#000; opacity: 1;}
.horizTableBlock :-ms-input-placeholder {color: #000;}
.horizTableBlock ::-ms-input-placeholder {color: #000;}
.horizTableBlock .placNone ::placeholder {color:#d3d3d3; opacity:0.5;}
.horizTableBlock .placNone :-ms-input-placeholder {color: #d3d3d3;}
.horizTableBlock .placNone ::-ms-input-placeholder {color: #d3d3d3;}
.paginationBlock.create .back{ margin-right:0px;}
.leftCont{float:left;  width:48%;}
.rightCont{float:right;  width:48%;}
.leftCont h3, .rightCont h3{ font-weight:600; color:#000000; padding-bottom:8px;}

.paginationBlock{ padding:20px 0; margin:0 auto; text-align:center;}
.paginationBlock .back{display:inline-block; margin-right:25px; }
.paginationBlock .back a, 
.paginationBlock .back input { 
  margin:0; padding:0px 5px; width:123px; font-size:14px; line-height:33px; color:#fff; border-radius:8px; display:block; font-weight:600; background:#4a4a4a; text-align:center;
  border-color: #4a4a4a;
}
.paginationBlock .back a:hover, 
.paginationBlock .back input:hover { 
  background:#6f6e6e; color:#fff;
}
.paginationBlock .next{ display:inline-block; margin-left:30px; }
.paginationBlock .next a, 
.paginationBlock .next input { 
  margin:0; padding:0px 5px; width:123px; font-size:14px; line-height:33px; color:#fff; border-radius:8px; display:block; font-weight:600; background:#ef4123; text-align:center;
  border-color: #ef4123;
}
.paginationBlock .next a:hover, 
.paginationBlock .next input:hover { 
  background:#f53918; color:#fff; 
}
.calenderWrapper {
  position: relative;
}
.horizTableBlock b.spaceTo{ margin:0 220px;}
.calenderPopper01 {
    position: absolute;
    top: -15px;
    left: 0px;  
}
.calenderPopper02 {
    position: absolute;
    top: -15px;
    left: 250px;  
}
.error { background-color: #fff0f0!important; }