@font-face {
    font-family: "Roboto Condensed";
    src: url("../../assets/fonts/RobotoCondensed-Regular.ttf");
}

body{font-family: 'Roboto Condensed', sans-serif;font-size:16px;line-height:1.6em;color:#000; font-weight:400; margin:0; padding:0;}
h1{font-size:36px;line-height:42px;margin:0;padding:0; letter-spacing:0px; font-weight:600; color:#000;} 
h2{font-size:24px;line-height:28px;margin:0;padding:0 0 15px 0;font-weight:600; color:#000;}
h3{color:#333333;font-size:16px;line-height:18px;margin:0; padding:0;}
h4{color:#333333;margin:0;padding:0;font-size:24px;line-height:28px;}
p{color:#4a494a;font-size:16px;line-height:20px;padding-bottom:15px;margin:0;}
a{color:#000;}
a:hover{ text-decoration:none;}


/* .App {
  text-align: center;
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/