.searchDiv { 
    float:left;
}

.searchDiv .searchInput { 
    border:solid 1px #9b9b9b; 
    border-radius:8px; 
    background-color:#f4f4f4; 
    background:#f4f4f4 url(../../../assets/images/search.png) 10px center no-repeat; 
    width:284px; 
    height:33px; 
    background-size:16px; 
    padding-left:35px; 
    padding-top: 1px;
    color:#9b9b9b; 
    position:relative; 
    z-index:999;
}

.searchDiv .send { 
    border:solid 1px #ef4123; 
    border-radius:8px; 
    background-color:#ef4123; 
    color:#fff; 
    height:33px; 
    outline:none; 
    width:123px; 
    font-size:18px; 
    font-weight:600; 
    margin-left:-14px; 
    cursor:pointer;
}