.adminDate{ font-size:16px;}
h1{ padding-bottom:20px; display:block;}
.serviceBlock{ padding:50px 0 0 0;}
.serviceBlock h2{ padding-bottom:15px;}
.leftCol{ float:left;}
.rightCol{ float:right; padding-top:55px;}
.bigAction {
    a.qrButton { 
        width:400px; 
        height:117px; 
        font-size:24px;
        span { 
            background-size:48px; 
            line-height:117px; 
            display:inline-block; 
            padding-left:80px;
        }
    }
}
a.qrButton { 
    background-color:#ef4123; 
    border-radius:8px; 
    line-height:50px; 
    width:220px; 
    font-size:20px; 
    font-weight:600; 
    color:#fff; 
    text-align:center; 
    display:inline-block;
}
a.qrButton span { 
    background:url(../../assets/images/icon-4.png) 0px center no-repeat;
    background-size:17px; 
    text-align:center; 
    padding-left:28px;
}
a.qrButton:hover{ background:#f84f32;}

.sortingDiv{ padding:30px 0 15px 0;}

.tableBlock .qrTabel{min-width: 1100px;}
.tableBlock { min-width:100%; overflow-x:scroll;}
.tableBlock .actTabel{ min-width:1583px;}
.tableBlock th{ background:#4a4a4a; color:#fff; font-size:14px; line-height:18px; padding:10px 15px;}
.tableBlock tr{ background:#f7f7f7;}
.tableBlock tr.light{ background:#ebebeb;}
.tableBlock tr td{ padding:10px 15px; font-size:14px; line-height:18px;}

.sorting{background:url(../../assets/images/filterIco.png) 0px bottom no-repeat; background-size:100%; width:5px; height:12px; display:inline-block; margin:0px 0 0 3px;}
.headerSort { cursor: pointer }

// .qrPage .modal {text-align: center; padding: 0!important;}
// .qrPage .modal:before {
//   content: '';
//   display: inline-block;
//   height: 100%;
//   vertical-align: middle;
//   margin-right: -4px; /* Adjusts for spacing */
// }
// .qrPage .modal-dialog {
//   display: inline-block;
//   text-align: left;
//   vertical-align: middle;
// }
// .qrPage .modal h1{font-size:20px; background: url(../images/QR-black.png) 0px 8px no-repeat; background-size:28px; padding-left:45px; display:inline-block;}
// .qrPage .modal-content{width:452px; height:246px; text-align:center; padding-top:20px;}
// .qrPage .modal-content p{color:#000; font-size:18px; padding-top:20px;}
// .qrPage .modal-body{ padding-bottom:0;}
// .qrPage .modal-footer{ text-align:center;}
// .qrPage .modal-footer{ border:none;}
// .qrPage .modal .paginationBlock .back{margin-right: 0;}