.paginationBlock { padding:20px 0; margin:0 auto; text-align:center;}
.paginationBlock .back {display:inline-block; margin-right:25px; }

.paginationBlock .back a,
.paginationBlock .next a 
{ margin:0; padding:0px 5px; width:123px; font-size:14px; line-height:33px; color:#fff; background:#4a4a4a; border-radius:8px; display:block; font-weight:600; text-align:center;}

.paginationBlock .back a:hover, 
.paginationBlock .next a:hover 
{ background:#6f6e6e; color:#fff;}

.paginationBlock .back a.active,
.paginationBlock .next a.active 
{ background:#ef4123; }

.paginationBlock .back a.active:hover,
.paginationBlock .next a.active:hover 
{ background:#f53918; }

.paginationBlock .back a[disabled],
.paginationBlock .next a[disabled]
{ pointer-events: none; }

.paginationBlock .next { display:inline-block; margin-left:30px; }

/* .paginationBlock .next a { margin:0; padding:0px 5px; width:123px; font-size:14px; line-height:33px; color:#fff; border-radius:8px; display:block; font-weight:600; background:#ef4123; text-align:center;}
.paginationBlock .next a:hover { background:#f53918; color:#fff;} */
.paginationBlock .numberPage { display:inline-block;}
.paginationBlock .numberPage ul { margin:0; padding:0;}
.paginationBlock .numberPage ul li{ margin:0; padding:0; display:inline-block; margin-left:10px;}
.paginationBlock .numberPage ul li a { margin:0; padding:0; display:block; color:#4a4a4a; line-height:30px; width:30px; text-align:center; font-size:14px; font-weight:600; border:solid 1px #4a4a4a;}
.paginationBlock .numberPage ul li a:hover { border:solid 1px #ef4123;color:#ef4123;}
.paginationBlock .numberPage ul li.active a { border:solid 1px #ef4123;color:#ef4123; pointer-events: none; }
.showingResult { text-align:right; font-size:12px; color:#9b9b9b; padding-top:5px;}