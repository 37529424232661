.loginPage {
    background:#fff url(../../assets/images/loginBg.png) center center fixed; 
    background-size:cover; 
    height: 100%; 
    min-height: 100vh; 
    width: 100%;
}

.loginPage .parent {
    display: table;  
    width: 100%; 
    min-height: 100vh; 
    height:100%; 
    max-width:400px; 
    margin:0 auto;
}	

.loginPage .innerCont { 
    width: 100%; 
    height:100%; 
    min-height: 100vh; 
    justify-content: center; 
    align-items: center; 
    padding:30px 15px 15px 15px;
    display:table-cell;
    vertical-align: middle;
}

.loginPage .logo {
    width:180px; 
    margin:0 auto;  
}

.loginPage .login-form { 
    margin-top:10px;
}

.loginPage .logo img {
    width:100%;
}

.loginPage .wrap-label {
    display:block; 
    font-size:20px; 
    color:#000000; 
    padding:40px 0 8px 0;
}

.input {
    border-radius:8px; 
    background-color:rgba(255, 255, 255, 0.8); 
    height:50px; 
    padding:0 15px; 
    line-height:20px; 
    border:none; 
    width:100%;
}

.loginPage .submit {
    background:#ef4123; 
    border-radius:8px; 
    display:block; 
    border:none; 
    width:100%; 
    color:#FFFFFF; 
    line-height:50px; 
    margin-top:80px; 
    outline:none; 
    cursor:pointer; 
    font-size:20px;
}

.loginPage .submit:hover {
    background:#f54c2f;
}